import React, { useState,useRef } from 'react';
import { getImage, GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { A } from '../components/styles'
import Group from "../components/group"
import Layout from "../components/layout"

export default function IndexPage({ pageContext: { node },location }) {
    return (
        <Layout>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'gemfisher',
              }}
            />
            <div className="pt-[260px] pb-[200px] lg:pt-[165px] lg:pb-[120px]">
              <article dangerouslySetInnerHTML={{__html:node.content}}/>
                <div className="flex items-center pt-14 pb-[200px] lg:pb-[120px] border-t border-black lg:flex-col lg:items-start">
                  <div className="text-[56px] leading-tight lg:pb-14 xl:text-[40px] sm:text-[32px]">Share on</div>
                  <ul className="grid grid-cols-4 gap-x-14 gap-y-8 ml-auto text-xl lg:ml-0 md:grid-cols-2 sm:grid-cols-1">
                    <li><A variant="link" href="#">LinkedIn</A></li>
                    <li><A variant="link" href="#">Facebook</A></li>
                    <li><A variant="link" href="#">Twitter</A></li>
                    <li><A variant="link" href="#">E-mail</A></li>
                  </ul>
                </div>
                {
                  node.details && node.details.length?
                  <div className="flex items-start pb-[200px] lg:pb-[120px] lg:flex-col">
                    <div className="text-[56px] w-1/4 lg:w-full lg:pb-14 xl:text-[40px] sm:text-[32px]">Details</div>
                    <div className="grid grid-cols-3 w-3/4 text-xl lg:w-full md:grid-cols-2 sm:grid-cols-1">
                      {
                        node.details.map((n,i)=>{
                          return (
                          <div key={i} className={`${i<node.details.length-1?'pb-[22px]':''}`}>
                            <div className="text-gray opacity-60 pb-3">{n.title}</div>
                            <ul>
                            {
                              n.list.map((e,j)=>{
                                return (<li key={j} className="pb-3"><A variant='border-gray' href={e.url?e.url:'#'}>{e.name}</A></li>)
                              })
                            }
                            </ul>
                          </div>)
                        })
                      }
                    </div>
                  </div>:null
                }
                {
                  node.designer?
                  <div className="flex items-start pb-[200px] lg:flex-col lg:pb-[120px]">
                    <div className="text-[56px] w-1/2 lg:w-full lg:pb-14 xl:text-[40px] sm:text-[32px]">Get in touch</div>
                    <div className="flex items-end w-1/2 lg:w-full xs:flex-col">
                      <figure className="w-1/2 xs:w-full xs:mb-10"><GatsbyImage className="w-full" image={getImage(node.designer.people.image.localFile)} alt="banner"/></figure>
                      <div className="w-1/2 ml-8 xs:w-full">
                        <div className="text-4xl leading-tight pb-4">{node.designer.people.name}</div>
                        <div className="text-xl">
                          <div className="pb-4">{node.designer.people.title}<br/>{node.designer.location}</div>
                          <a href={`mailto:${node.designer.people.email}`}>{node.designer.people.email}</a>
                        </div>
                      </div>
                    </div>
                  </div>:null
                }
                <div className="border-t border-black pt-14">
                  <div className="text-[56px] leading-tight pb-14 xl:text-[40px] sm:text-[32px]">Related</div>
                  <div className="grid grid-cols-4 gap-8 pb-[95px] grid-list lg:grid-cols-2 sm:grid-cols-1">
                  {
                      node.work_details.map((n,i)=>{
                          return (
                              <Group category={n.title} lg={true} key={i} node={n}/>
                          )
                      })
                  }
                  </div>
                  <A href="/stories" variant="more">Show more work</A>
                </div>
              </div>
        </Layout>
    )
}

